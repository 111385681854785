export function dag_detector(adjacencyList) {
  const visited = {}
  const explored = {}
  let is_dag = true

  function explore(head, tails) {
    explored[head] = true

    for (var i = 0; i < tails.length; i++) {
      const key = tails[i]

      if (explored[key]) {
        console.log('NOT A DAG')
        is_dag = false
      }

      if (visited[key] === false && explored[key] === false) {
        explore(key, adjacencyList[key])
      }
    }
  }

  const keys = Object.keys(adjacencyList)

  for (var i = 0; i < keys.length; i++) {
    const key = keys[i]

    visited[key] = false
  }

  for (var i = 0; i < keys.length; i++) {
    const key = keys[i]

    if (visited[key] === false) {
      explore(key, adjacencyList[key])
    }

    visited[key] = true
  }

  // console.log(visited)
  // console.log(explored)

  return is_dag
}
