import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

import React from 'react';
import _ from 'underscore';
import { dfs } from './dfs';
import { topological_order } from './topological_order';
import { dijkstra } from './dijkstra';
import { scc } from './scc';
import { flip } from './flip';
import { dag_detector } from './dag_detector';
import { Button, Tag } from 'antd';

class DirectedWeighted extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { adjacencyListWeighted } = this.props

    const adjacencyList = {}

    for (var i = 0; i < Object.keys(adjacencyListWeighted).length; i++) {
      const key = Object.keys(adjacencyListWeighted)[i]
      const vals = adjacencyListWeighted[key]

      adjacencyList[key] = []
      
      for (var j = 0; j < Object.keys(vals).length; j++) {
        adjacencyList[key].push(vals[j][0])
      }
    }

    const SCCs = scc(adjacencyList)
    const topo_order = topological_order(adjacencyList)
    const s = Object.keys(adjacencyListWeighted)[0]
    const shortest_paths = dijkstra(adjacencyListWeighted, s)

    return (
      <React.Fragment>
        <div className="px-2 py-1 d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f5f5f5' }}>
          <b>Information</b>
        </div>

        <div className="mb-3 p-3" style={{ backgroundColor: 'white' }}>
          {
            dag_detector(adjacencyList) ? 
            <Tag color="success">
              <div>
                <b>☑ DAG: Directed Acyclic</b>
              </div>
            </Tag>
            :
            <Tag color="default">
              <div className='m-auto'>
                ✕ DAG: Directed Acyclic
              </div>
            </Tag>
          }

          <p>
            There are {SCCs.length} Strongly Connected Components (SCCs):
            <ul>
              {
                SCCs.map((e,i) => {
                  return (<li>SCC #{String(i+1)}: {String(e)}</li>)
                })
              }
            </ul>
          </p>

          <p>
            Topological Order:
            <li>
              Pre: {JSON.stringify(topo_order[0])}
            </li>
            <li>
              Post: {JSON.stringify(topo_order[1])}
            </li>
          </p>

          <p>
            Shortest path from "{s}" (Dijkstra's Algorithm):
            {
              Object.keys(shortest_paths).map(e => (
                <li>
                  From "{s}" to "{e}": {shortest_paths[e]}
                </li>
              ))
            }
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default DirectedWeighted
