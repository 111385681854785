import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

import React from 'react';
import Cytoscape from 'cytoscape';
import CytoscapeComponent from 'react-cytoscapejs';
import dagre from 'cytoscape-dagre';
import _ from 'underscore';
import { dfs } from './dfs';
import Undirected from './Undirected';
import Directed from './Directed';
import DirectedWeighted from './DirectedWeighted';
import UndirectedWeighted from './UndirectedWeighted';
import { topological_order } from './topological_order';
import { dag_detector } from './dag_detector';
import AceEditor from "react-ace";
import { Select, Form, Alert } from 'antd';

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import 'antd/dist/antd.css';

Cytoscape.use(dagre);

const directedStylesheet = [
  {
    selector: 'node',
    style: {
      backgroundColor: '#666',
      label: 'data(id)'
    }
  },
  {
    selector: 'edge',
    style: {
      width: 4,
      lineColor: '#ccc',
      targetArrowColor: '#ccc',
      targetArrowShape: 'triangle',
      curveStyle: 'bezier',
    }
  }
]

const directedWeightedStylesheet = [
  {
    selector: 'node',
    style: {
      backgroundColor: '#666',
      label: 'data(id)'
    }
  },
  {
    selector: 'edge',
    style: {
      width: 4,
      lineColor: '#ccc',
      targetArrowColor: '#ccc',
      targetArrowShape: 'triangle',
      curveStyle: 'bezier',
      label: 'data(weight)'
    }
  }
]

const undirectedWeightedStylesheet = [
  {
    selector: 'node',
    style: {
      backgroundColor: '#666',
      label: 'data(id)'
    }
  },
  {
    selector: 'edge',
    style: {
      width: 4,
      lineColor: '#ccc',
      curveStyle: 'bezier',
      label: 'data(weight)'
    }
  }
]

const undirectedStylesheet = [
  {
    selector: 'node',
    style: {
      backgroundColor: '#666',
      label: 'data(id)'
    }
  },
  {
    selector: 'edge',
    style: {
      width: 4,
      lineColor: '#ccc',
      curveStyle: 'bezier',
    }
  }
]

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adjacencyListInString: '',
      adjacencyList: {},
      graphType: 'undirected_weighted',
      valid: true
    };
  }

  isAdjacencyListStringValid = (adjacencyListInString) => {
    try {
      // this.state.adjacencyList
      switch (this.state.graphType) {
        case 'directed_weighted':
          return this.isAdjacencyListValidForDW(JSON.parse(this.state.adjacencyListInString))
        case 'undirected_weighted':
          return this.isAdjacencyListValidForDW(JSON.parse(this.state.adjacencyListInString))
        default:
          return this.isAdjacencyListValid(JSON.parse(this.state.adjacencyListInString))
      }
      // return true
    } catch (e) {
      // console.log(e)
      return false
    }
  }

  isAdjacencyListValid = (adjacencyList) => {
    const nodes = []
    let edges = []

    if (Object.keys(adjacencyList).indexOf("") != -1) {
      console.log("FALSEEEEE")
      return false
    }

    Object.keys(adjacencyList).map(key => {
      nodes.push(key)
      
      adjacencyList[key].map(val => {
        edges.push(val)
      })
    })

    edges = _.uniq(edges)

    for (var i = 0; i < edges.length; i++) {
      if (nodes.indexOf(edges[i]) == -1){
        return false
      }
    }

    return true
  }

  isAdjacencyListValidForDW = (adjacencyList) => {
    const nodes = []
    let edges = []
    let is_valid = true

    if (Object.keys(adjacencyList).indexOf("") != -1) {
      console.log("FALSEEEEE")
      return false
    }

    Object.keys(adjacencyList).map(key => {
      nodes.push(key)
      
      adjacencyList[key].map(val => {
        edges.push(val[0])

        if (val.length == 1) {
          is_valid = false
        }
      })
    })

    edges = _.uniq(edges)

    for (var i = 0; i < edges.length; i++) {
      if (nodes.indexOf(edges[i]) == -1){
        return false
      }
    }

    return is_valid
  }

  handleAdjacencyListChange = (newValue) => {
    this.setState({ adjacencyListInString: newValue }, () => {

      try {
        if (this.isAdjacencyListStringValid(this.state.adjacencyList)) {
          this.setState({ adjacencyList: JSON.parse(this.state.adjacencyListInString) }, () => {
            this.setState({ valid: true })
          })
        } else {
          console.log('adjacencyList is not valid')
          this.setState({ valid: false })
        }
      } catch (e) {
        console.log('adjacencyList is not valid')
        this.setState({ valid: false })
        // console.log(e)
      }
    });
  }

  adjacencyListToElements = (adjacencyList, graphType) => {
    const elements = [];

    switch (graphType) {
      case 'directed':
        for (var i = 0; i < Object.keys(adjacencyList).length; i++) {
          const key = Object.keys(adjacencyList)[i]

          elements.push({
            data: {
              id: key
            }
          })

          const values = adjacencyList[key]

          for (var j = 0; j < values.length; j++) {
            if (Object.keys(adjacencyList).indexOf(values[j]) != -1) {
              elements.push({
                data: {
                  id: String(key) + String(values[j]),
                  source: key,
                  target: values[j],
                }
              })
            }
          }
        }
        break;
      case 'directed_weighted':
        for (var i = 0; i < Object.keys(adjacencyList).length; i++) {
          const key = Object.keys(adjacencyList)[i]

          elements.push({
            data: {
              id: key
            }
          })

          const values = adjacencyList[key]
          const nodes = values.map(e => e[0])

          for (var j = 0; j < values.length; j++) {
            if (Object.keys(adjacencyList).indexOf(nodes[j]) != -1) {
              elements.push({
                data: {
                  id: String(key) + String(nodes[j]),
                  source: key,
                  target: values[j][0],
                  weight: values[j][1],
                }
              })
            }
          }
        }
        break;
      case 'undirected':
        for (var i = 0; i < Object.keys(adjacencyList).length; i++) {
          const key = Object.keys(adjacencyList)[i]

          elements.push({
            data: {
              id: key
            }
          })

          const values = adjacencyList[key]

          for (var j = 0; j < values.length; j++) {
            if (Object.keys(adjacencyList).indexOf(values[j]) != -1 && elements.map(function (e) { return e.data.id; }).indexOf(String(values[j]) + String(key)) === -1) {
              elements.push({
                data: {
                  id: String(key) + String(values[j]),
                  source: key,
                  target: values[j],
                }
              })
            }
          }
        }
        break;
      case 'undirected_weighted':
        for (var i = 0; i < Object.keys(adjacencyList).length; i++) {
          const key = Object.keys(adjacencyList)[i]

          elements.push({
            data: {
              id: key
            }
          })

          const values = adjacencyList[key]
          const nodes = values.map(e => e[0])
          console.log(nodes)

          for (var j = 0; j < values.length; j++) {
            if (elements.map(function (e) { return e.data.id; }).indexOf(String(nodes[j]) + String(key)) === -1) {
              elements.push({
                data: {
                  id: String(key) + String(nodes[j]),
                  source: key,
                  target: values[j][0],
                  weight: values[j][1],
                }
              })
            }
          }
        }
        break;
      default:
        break;
    }

    return elements
  }

  handleCy = (cy) => {
    cy.layout({
      name: 'dagre'
    }).run()
  }

  replaceWithExample = (e) => {
    switch (e) {
      case 'undirected_simple':
        this.handleAdjacencyListChange('{\n "A": ["B","C"],\n "B": ["A"],\n "C": ["A","D"],\n "D": ["C","E"],\n "E": ["D"]\n}')
        break;
      case 'dag':
        this.handleAdjacencyListChange('{\n "A": ["B","C"],\n "B": [],\n "C": ["D"],\n "D": ["E"],\n "E": []\n}')
        break;
      case 'scc':
        this.handleAdjacencyListChange('{\n "A": ["B","C"],\n "B": ["G"],\n "C": ["D"],\n "D": ["E"],\n "E": ["F"],\n "F": ["D"],\n "G": ["H"],\n "H": ["B"] \n}')
        break;
      case 'dijkstra':
        this.handleAdjacencyListChange('{ \n  "A": [["B",10],["C",5]],\n  "B": [["G",2],["H",4]],\n  "C": [["D",3]],\n  "D": [["E",5]],\n  "E": [["F",8]],\n  "F": [["D",9]],\n  "G": [["H",1]],\n  "H": [] \n}')
        break;
      case 'undirected_dijkstra':
        this.handleAdjacencyListChange('{ \n  "A": [["B",10],["C",5]],\n  "B": [["A",10],["G",2],["H",4]],\n  "C": [["A",5],["D",3]],\n  "D": [["C",3],["E",5],["F",9]],\n  "E": [["D",5],["F",8]],\n  "F": [["D",9],["E",8]],\n  "G": [["B",2],["H",1]],\n  "H": [["B",4],["G",1]] \n}')
        break;
      default:
        break;
    }
  }

  changeGraphType = (e) => {
    this.setState({ graphType: e, adjacencyList: {}, adjacencyListInString: '' }, () => {
      switch (e) {
        case 'undirected':
          this.replaceWithExample('undirected_simple')
          break;
        case 'directed':
          this.replaceWithExample('scc')
          break;
        case 'directed_weighted':
          this.replaceWithExample('dijkstra')
          break;
        case 'undirected_weighted':
          this.replaceWithExample('undirected_dijkstra')
          break;
        default:
          break;
      }
    })
  }

  renderExampleChoices = () => {
    switch (this.state.graphType) {
      case 'undirected':
        return [{ symbol: 'undirected_simple', text: 'Simple undirected graph example' }]
      case 'directed':
        return [{ symbol: 'dag', text: 'Directed Acyclic (DAG)' }, { symbol: 'scc', text: 'Find SCCs' }]
      case 'directed_weighted':
        return [{ symbol: 'dijkstra', text: "Dijstra's" }]
      default:
        return []
    }
  }

  renderGraph(adjacencyList, graphType) {
    let elements;
    let ss;
    
    try {
      elements = this.adjacencyListToElements(adjacencyList, graphType)
    } catch (e) {
      console.log(e)
      elements = []
    }

    switch (graphType) {
      case 'directed':
        ss = directedStylesheet
        break;
      case 'directed_weighted':
        ss = directedWeightedStylesheet
        break;
      case 'undirected':
        ss = undirectedStylesheet
        break;
      case 'undirected_weighted':
        ss = undirectedWeightedStylesheet
        // console.log(elements)
        break;
      default:
        break;
    }
    
    return (
      <CytoscapeComponent
        elements={elements}
        style={{ width: '100%', height: '70vh' }}
        layout={{
          name: 'dagre'
        }}
        stylesheet={ss}
        cy={this.handleCy}
        userZoomingEnabled={false}
        userPanningEnabled={false}
      />
    )
  }

  componentDidMount() {
    this.changeGraphType(this.state.graphType)
  }
    
  render() {
    const { Option } = Select;
    const { valid } = this.state;

    return (
      <div className="container-fluid" style={{ backgroundColor: '#e3e8ee' }}>
        <nav className="navbar navbar-light px-0 py-1">
          <div>
            <Form>
              <Form.Item label="Graph Type" className="m-0">
                <Select
                  label="Password"
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Select a Graph Type"
                  optionFilterProp="children"
                  value={this.state.graphType}
                  onChange={this.changeGraphType}
                >
                  <Option value="directed">Directed Graph</Option>
                  <Option value="directed_weighted">Directed Graph (Weighted)</Option>
                  <Option value="undirected">Undirected Graph</Option>
                  <Option value="undirected_weighted">Undirected Graph (Weighted)</Option>
                </Select>
              </Form.Item>
            </Form>
          </div>

          <div className="ml-auto">
            <img src="/logo.png?v2" width="255px" height="auto"></img>
          </div>
        </nav>

        <div className="row">
          <div className="col-6">
            <div className="px-2 py-1 d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f5f5f5' }}>
              <b>Adjacency List</b>

              <div className="d-flex">
                <Select
                  label="Password"
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Choose an example"
                  optionFilterProp="children"
                  className="mr-2"
                  onChange={this.replaceWithExample}
                >
                  {this.renderExampleChoices().map(e => (
                    <Option value={e.symbol}>{e.text}</Option>
                  ))}
                </Select>

                {valid ? null : <Alert message="Invalid" type="error" showIcon className="py-0 px-2" />}
                {valid ? <Alert message="Valid" type="success" showIcon className="py-0 px-2" /> : null}
              </div>
            </div>

            <div className="mb-3">
              <AceEditor
                mode="json"
                theme="github"
                name="adjacencyListInputBox"
                value={this.state.adjacencyListInString}
                onChange={this.handleAdjacencyListChange}
                height={250}
                width="100%"
                wrapEnabled={true}
              />
            </div>

            {Object.keys(this.state.adjacencyList).length != 0 && this.state.valid && this.state.graphType == 'undirected' && <Undirected adjacencyList={this.state.adjacencyList} />}

            {Object.keys(this.state.adjacencyList).length != 0 && this.state.valid && this.state.graphType == 'directed_weighted' && <DirectedWeighted adjacencyListWeighted={this.state.adjacencyList} />}

            {Object.keys(this.state.adjacencyList).length != 0 && this.state.valid && this.state.graphType == 'undirected_weighted' && <UndirectedWeighted adjacencyListWeighted={this.state.adjacencyList} />}

            {Object.keys(this.state.adjacencyList).length != 0 && this.state.valid && this.state.graphType == 'directed' && <Directed adjacencyList={this.state.adjacencyList} handleAdjacencyListChange={this.handleAdjacencyListChange} />}
          </div>

          <div className="col-6">
            <div className="px-2 py-1 d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f5f5f5' }}>
              <b>Graph Diagram</b>
              {valid ? null : <Alert message="Unable to render" type="error" showIcon className="py-0 px-2 py-1" />}
              {valid ? <Alert message="Rendered successfully" type="success" showIcon className="py-0 px-2 py-1" /> : null}
            </div>

            <div className="px-2 py-1 d-flex justify-content-between align-items-center mb-3" style={{ backgroundColor: 'white' }}>
              {this.renderGraph(this.state.adjacencyList, this.state.graphType) }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App
