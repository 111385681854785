export function topological_order(adjacencyList) {
  let clock = 1
  const pre = {}
  const post = {}
  const visited = {}

  function explore(head, tails) {
    visited[head] = true
    pre[head] = clock
    clock++

    for (var i = 0; i < tails.length; i++) {
      const key = tails[i]

      if (visited[key] === false) {
        explore(key, adjacencyList[key])
      }
    }
    post[head] = clock
    clock++
  }

  const keys = Object.keys(adjacencyList)

  for (var i = 0; i < keys.length; i++) {
    const key = keys[i]

    visited[key] = false
  }

  for (var i = 0; i < keys.length; i++) {
    const key = keys[i]

    if (visited[key] === false) {
      explore(key, adjacencyList[key])
    }
  }

  // console.log(visited)
  // console.log('pre', pre)
  // console.log('post', post)

  return [pre, post]
}
