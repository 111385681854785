export function dfs(adjacencyList) {
  let cc = 0
  const cc_num = {}
  const visited = {}

  function explore(head, tails) {
    visited[head] = true
    cc_num[head] = cc

    for (var i = 0; i < tails.length; i++) {
      const key = tails[i]

      if (visited[key] === false) {
        explore(key, adjacencyList[key])
      }
    }
  }

  const keys = Object.keys(adjacencyList)

  for (var i = 0; i < keys.length; i++) {
    const key = keys[i]

    visited[key] = false
  }

  for (var i = 0; i < keys.length; i++) {
    const key = keys[i]

    if (visited[key] === false) {
      cc += 1
      explore(key, adjacencyList[key])
    }
  }

  // console.log(visited)
  // console.log(cc_num)

  return cc_num
}
