import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

import React from 'react';
import _ from 'underscore';
import { dfs } from './dfs';
import { topological_order } from './topological_order';
import { scc } from './scc';
import { flip } from './flip';
import { dag_detector } from './dag_detector';
import { Button, Tag } from 'antd';

class Directed extends React.Component {
  constructor(props) {
    super(props);
  }

  flipGraph = () => {
    const { adjacencyList, handleAdjacencyListChange } = this.props

    handleAdjacencyListChange(JSON.stringify(flip(adjacencyList)))
  }

  render() {
    const { adjacencyList } = this.props

    const SCCs = scc(adjacencyList)

    const topo_order = topological_order(adjacencyList)

    // console.log(topological_order(adjacencyList))
    // console.log(dag_detector(adjacencyList))
    // console.log('SCCs:', SCCs)
    // console.log('flip:', flip(adjacencyList))

    // scc(adjacencyList)

    return (
      <React.Fragment>
        <div className="px-2 py-1 d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f5f5f5' }}>
          <b>Information</b>
        </div>

        <div className="mb-3 p-3" style={{ backgroundColor: 'white' }}>
          {
            dag_detector(adjacencyList) ? 
            <Tag color="success">
              <div>
                <b>☑ DAG: Directed Acyclic</b>
              </div>
            </Tag>
            :
            <Tag color="default">
              <div className='m-auto'>
                ✕ DAG: Directed Acyclic
              </div>
            </Tag>
          }

          <p>
            There are {Object.keys(SCCs).length} Strongly Connected Components (SCCs):
            <ul>
              {
                SCCs.map((e, i) => {
                  return (<li>SCC #{String(i + 1)}: {String(e)}</li>)
                })
              }
            </ul>
          </p>

          <p>
            Topological Order:
            <li>
              Pre: {JSON.stringify(topo_order[0])}
            </li>
            <li>
              Post: {JSON.stringify(topo_order[1])}
            </li>
          </p>
        </div>

        <div className="mb-3 p-3" style={{ backgroundColor: 'white' }}>
          <Button onClick={this.flipGraph}>Flip Graph</Button>
        </div>
      </React.Fragment>
    );
  }
}

export default Directed
