export function remove_node(adjacencyList, node) {
  const al = {}

  const keys = Object.keys(adjacencyList)

  for (var i = 0; i < keys.length; i++) {
    const key = keys[i]
    const vals = adjacencyList[key]

    if (key != node) {
      al[key] = []

      for (var j = 0; j < vals.length; j++) {
        if (vals[j] != node) {
          al[key].push(vals[j])
        }
      }
    }
  }

  return al
}
