export function flip(adjacencyList) {
  const keys = Object.keys(adjacencyList)

  const al = {}

  for (var i = 0; i < keys.length; i++) {
    al[keys[i]] = []
  }

  for (var i = 0; i < keys.length; i++) {
    const key = keys[i]
    const vals = adjacencyList[key]

    for (var j = 0; j < vals.length; j++) {
      const val = vals[j]

      al[val].push(key)
    }
  }

  return al
}
