export function dijkstra(adjacencyList, s) {
  const nodes = Object.keys(adjacencyList)
  const dist = {}
  const visited = {}

  for (var i = 0; i < nodes.length; i++) {
    const node = nodes[i]

    dist[node] = Infinity
    visited[node] = false
  }
  dist[s] = 0

  function explore(head, edges) {
    visited[head] = true

    for (var i = 0; i < edges.length; i++) {
      const edge = edges[i][0]
      const edge_length = edges[i][1]

      if (dist[edge] > edge_length + dist[head]) {
        dist[edge] = edge_length + dist[head]
      }

      if (visited[edge] === false) {
        explore(edge, adjacencyList[edge])
      }
    }
  }

  const edges = adjacencyList[s]
  explore(s, edges)

  // console.log(dist)
  // console.log(visited)

  return dist
}
