import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

import React from 'react';
import _ from 'underscore';
import { dfs } from './dfs';
import { topological_order } from './topological_order';
import { dag_detector } from './dag_detector';
import { Select, Form, Alert } from 'antd';

class Undirected extends React.Component {
  constructor(props) {
    super(props);
  }

  componentsList = (cc_num) => {
    const components = {}

    Object.keys(cc_num).map(key => {
      const val = String(cc_num[key])

      if (Object.keys(components).indexOf(val) == -1) {
        components[val] = []
      }

      // console.log(key)
      components[val].push(key)
    })

    // console.log(components)

    return components
  }

  render() {
    const components = this.componentsList(dfs(this.props.adjacencyList))

    // console.log(dfs(this.props.adjacencyList))

    return (
      <React.Fragment>
        <div className="px-2 py-1 d-flex justify-content-between align-items-center" style={{ backgroundColor: '#f5f5f5' }}>
          <b>Information</b>
        </div>

        <div className="mb-3 p-3" style={{ backgroundColor: 'white' }}>
          <p>
            There are {Object.keys(components).length} components:
            <ul>
              {
                Object.keys(components).map((key) => {
                  return (<li>Component #{key}: {String(components[key])}</li>)
                })
              }
            </ul>
          </p>

        </div>
      </React.Fragment>
    );
  }
}

export default Undirected
