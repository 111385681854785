import { flip } from './flip';
import { topological_order } from './topological_order';
import { remove_node } from './manipulate';

export function scc(adjacencyList) {
  const visited = {}
  const SCCs = []
  let scc

  function explore(head) {
    visited[head] = true
    scc.push(head)

    const tails = adjacencyList[head]

    for (var i = 0; i < tails.length; i++) {
      const key = tails[i]

      if (visited[key] === false) {
        explore(key)
      }
    }
  }

  const keys = Object.keys(adjacencyList)
  for (var i = 0; i < keys.length; i++) {
    const key = keys[i]

    visited[key] = false
  }

  while (Object.keys(adjacencyList).length !== 0) {
    scc = []

    const flipped = flip(adjacencyList)
    const post = topological_order(flipped)[1]
    const sink = Object.keys(post).reduce((a, b) => post[a] > post[b] ? a : b)
    
    explore(sink)
    
    for (var i = 0; i < scc.length; i++) {
      adjacencyList = remove_node(adjacencyList, scc[i])
    }

    SCCs.push(scc)
  }

  console.log(adjacencyList)

  return SCCs
}
